<template>
  <div class="page" :style="{'minHeight':cHeight}">
    <div class="box" :class="showAnswer?'down':''" @click="showAnswer=!showAnswer">
      <div class="lists">
        <div class="question">
          <img src="../assets/ticket_icon_message.png" alt="">
          <span>优惠券可以兑换现金吗？</span>
        </div>
        <img class="arrow" :class="showAnswer?'xuan':''" src="../assets/card_btn_arrow_up.png" alt="">
      </div>
      <div class="answer">
        <span style="font-weight: bold;margin-right:10px">A:</span>
        <span>不可以。优惠券只能用于抵扣服务费用，不可兑换现金。</span>
      </div>
    </div>
    <div class="box" :class="showAnswer1?'down':''" @click="showAnswer1=!showAnswer1">
      <div class="lists">
        <div class="question">
          <img src="../assets/ticket_icon_message.png" alt="">
          <span>优惠券在使用期限上有什么限制？</span>
        </div>
        <img class="arrow" :class="showAnswer1?'xuan':''" src="../assets/card_btn_arrow_up.png" alt="">
      </div>
      <div class="answer">
        <span style="font-weight: bold;margin-right:10px">A:</span>
        <span>优惠券只能在标示出的有效期内使用，超过有效期的则过期失效。</span>
      </div>
    </div>
    <div class="box" :style="{'height':showAnswer2?'130px':''}" @click="showAnswer2=!showAnswer2">
      <div class="lists">
        <div class="question">
          <img src="../assets/ticket_icon_message.png" alt="">
          <span>优惠券的用途是什么？</span>
        </div>
        <img class="arrow" :class="showAnswer2?'xuan':''" src="../assets/card_btn_arrow_up.png" alt="">
      </div>
      <div class="answer">
        <span style="font-weight: bold;margin-right:10px">A:</span>
        <span>优惠券会标注限制服务的类型，如咨询券只可用于抵扣当次咨询所需支付的费用，未标注限制条件的则可通用。</span>
      </div>
    </div>
    <div class="box" :class="showAnswer3?'down':''" @click="showAnswer3=!showAnswer3">
      <div class="lists">
        <div class="question">
          <img src="../assets/ticket_icon_message.png" alt="">
          <span>优惠券能不能叠加使用？</span>
        </div>
        <img class="arrow" :class="showAnswer3?'xuan':''" src="../assets/card_btn_arrow_up.png" alt="">
      </div>
      <div class="answer">
        <span style="font-weight: bold;margin-right:10px">A:</span>
        <span>当次咨询只能使用一张优惠券，不可叠加使用。</span>
      </div>
    </div>
    <div class="box" :class="showAnswer4?'down':''" @click="showAnswer4=!showAnswer4">
      <div class="lists">
        <div class="question">
          <img src="../assets/ticket_icon_message.png" alt="">
          <span>优惠券差额部分可以退还吗？</span>
        </div>
        <img class="arrow" :class="showAnswer4?'xuan':''" src="../assets/card_btn_arrow_up.png" alt="">
      </div>
      <div class="answer">
        <span style="font-weight: bold;margin-right:10px">A:</span>
        <span>使用的优惠券金额超过订单所需支付的差额部分并不退还。</span>
      </div>
    </div>
    <div class="box" :class="showAnswer5?'down':''" @click="showAnswer5=!showAnswer5">
      <div class="lists">
        <div class="question">
          <img src="../assets/ticket_icon_message.png" alt="">
          <span>同一天能够使用多少张优惠券？</span>
        </div>
        <img class="arrow" :class="showAnswer5?'xuan':''" src="../assets/card_btn_arrow_up.png" alt="">
      </div>
      <div class="answer">
        <span style="font-weight: bold;margin-right:10px">A:</span>
        <span>同一天内多次使用的，使用优惠券共计不得超过三张。</span>
      </div>
    </div>
    <div class="box" :class="showAnswer6?'down':''" @click="showAnswer6=!showAnswer6">
      <div class="lists">
        <div class="question">
          <img src="../assets/ticket_icon_message.png" alt="">
          <span>一账号内的优惠券能否转让或代为支付？</span>
        </div>
        <img class="arrow" :class="showAnswer6?'xuan':''" src="../assets/card_btn_arrow_up.png" alt="">
      </div>
      <div class="answer">
        <span style="font-weight: bold;margin-right:10px">A:</span>
        <span>账户内的优惠券只可使用于本账户内的订单，不得转让或代为支付另一 账户内的订单。</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        cHeight: null,
        activeName: 1,
        showAnswer: false,
        showAnswer1: false,
        showAnswer2: false,
        showAnswer3: false,
        showAnswer4: false,
        showAnswer5: false,
        showAnswer6: false,

      }
    },
    mounted() {
      this.cHeight = (document.documentElement.clientHeight || document.body.clientHeight) + 'px'

    },
    methods: {

    }
  }

</script>
<style scoped lang='scss'>
  .page {
    padding-top: 30px;
    background: #F5F5F8;
    box-sizing: border-box;
  }

  .box {
    margin: 0 auto 30px;
    width: 690px;
    height: 120px;
    background: #FFFFFF;
    border-radius: 10px;
    transition: height 0.5s;
    -webkit-transition: height 0.5s;
    overflow: hidden;
  }

  .down {
    height: 210px;
  }

  .lists {
    display: flex;
    height: 120px;
    justify-content: space-between;
    align-items: center;
  }

  .question {
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: bold;
    color: #333333;

    img {
      margin: 0 19px 0 30px;
      width: 40px;
      height: 40px;
    }
  }

  .arrow {
    margin-right: 20px;
    width: 23px;
    height: 14px;
  }

  .xuan {
    transform: rotate(180deg);
  }

  .answer {
    margin: 0 80px 0 43px;
    font-size: 26px;
    color: #666666;
    line-height: 36px;
  }

</style>
